@import 'config';
@import "home.scss";
@import "header.scss";
@import "footer.scss";
@import "about-us.scss";
@import "contact-us.scss";

.page-body{
  min-height: calc(100vh - 110px);
} 

