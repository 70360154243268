.contact-us {
  display: flex;
  justify-content: center;
  padding: 15px 0;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  .contact-us-wrapper {
    padding: 0 50px;
    width: 80vw;
    background-color: #eff0f5;
    position: relative;

    @media (max-width: 600px) {
      width: 90vw;
      padding: 0;
    }

    .contact-us-top {
      width: 100%;
      text-align: center;
      .contact-us-title {
        font-size: 45px;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        @media (max-width: 767px) {
          font-size: 35px;
        }

        @media (max-width: 480px) {
          font-size: 28px;
        }
      }
      .contact-us-explain {
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        font-size: 16px;
      }
    }

    .contact-us-info {
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 1300px) {
        flex-direction: column;
      }

      .contact-us-info-item {
        padding: 15px;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: 10px;
        align-items: center;
        background-color: white;
        border-radius: 10px;
        border: 1px solid  rgb(151, 83, 214);


        .explain {
          padding: 15px;
          font-size: 13px;
          font-weight: 500;
        }

        .upper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          .icon-wrapper {
            height: 70px;
            width: 70px;
            border-radius: 50%;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid  rgb(151, 83, 214);
            .company-info-icon {
              height: 35px;
              width: 35px;
              color:  rgb(0, 0, 0);
            }
          }
          .text {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 10px;
            font-weight: 700;
            font-size: 14px;
            p {
              margin: 0;
              padding: 0;
            }
          }
        }
        .lower {
          font-weight: 600;
          text-align: center;

          .words {
            color: rgb(106, 36, 172);
            padding: 25px 0;
          }
          .company-info-text {
            text-decoration: none;
            color: #000;
            font-weight: 700;
            font-size: 12px;
            text-align: center;
          }
      

        }
      }
    }

    .contact-us-social {
      // background-color: rgba(211, 180, 240, 0.499);
      background-color: white;
      border: 1px solid  rgb(151, 83, 214);
      padding: 20px;
      color: black;
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 20px 0 0;
      border-radius: 10px;

      .social-lazada-img {
        width: 50px;
        height: 50px;
        padding: 2px 2px;
        image-rendering: optimizeQuality;

        @media (max-width: 480px) {
          width: 40px;
          height: 40px;
          padding: 1px 1px;
        }
      }
      .social-shopee-img {
        width: 50px;
        height: 50px;
        padding: 2px 4px 5px 3px;
        image-rendering: optimizeQuality;

        @media (max-width: 480px) {
          width: 40px;
          height: 40px;
          padding: 1px 2px 3px 2px;
        }
      }

      .ezsense-logo {
        width: 100px;
        height: 100px;
      }

      .social-link {
        text-decoration: none;
        color: black;
        margin: 0 15px;
        @media (max-width: 1300px) {
          margin: 0 11px;
        }
        @media (max-width: 767px) {
          margin: 0 6px;
        }

        @media (max-width: 480px) {
          margin: 0 4px;
        }

        .social-icon {
          font-size: 50px;

          @media (max-width: 480px) {
            font-size: 40px;
          }
        }
      }
    }
  }
}
