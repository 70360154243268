.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding: 15px 0 10px;
  background-color: white;
  color: rgba(0, 0, 0, 0.65);
  font-size: 13px;
  font-weight: 600;
  p{
    margin: 0;
  }
}
